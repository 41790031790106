import React from "react";
import PigeonOverlay from "pigeon-overlay";
import styled from "styled-components";

const markerImg = require("../images/mapDot.svg");

const MarkerButton = styled.button`
  height: 20px;
  width: 20px;
  background: none;
  background-image: url(${markerImg});
  border: none;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    width: 25px;
    height: 25px;
    transform: translate(-2.5px, -2.5px);
  }
`;

function MapMarker({ place, anchor, left, top, onClick }) {
  const rLeft = Math.round(left);
  const rTop = Math.round(top);

  return (
    <PigeonOverlay
      anchor={anchor}
      left={rLeft}
      top={rTop}
      style={{ userSelect: "none" }}
    >
      <MarkerButton onClick={() => onClick(place)}></MarkerButton>
    </PigeonOverlay>
  );
}

export default MapMarker;
