import React from "react";
import PigeonOverlay from "pigeon-overlay";
import styled from "styled-components";

const InfoWindowWrapper = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  max-width: 200px;
  font-weight: 300;
  font-size: 14px;

  color: #222222;

  .name {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function InfoWindow({ anchor, left, top, place }) {
  const rLeft = Math.round(left);
  const rTop = Math.round(top);

  return (
    <PigeonOverlay
      anchor={anchor}
      left={rLeft}
      top={rTop}
      className="infoWindow pigeon-drag-block"
    >
      <InfoWindowWrapper>
        <div className="name">{place.name}</div>
        <div className="address">{place.address}</div>
      </InfoWindowWrapper>
    </PigeonOverlay>
  );
}

export default InfoWindow;
