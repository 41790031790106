import { useStaticQuery, graphql } from "gatsby";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import InfoWindow from "../components/InfoWindow";
import Map from "../components/Map";
import MapMarker from "../components/MapMarker";

const MapContainerWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  .infoWindow {
    z-index: 1000;
  }
`;

const renderMarkers = (places, setActivePlace) => {
  return places.map(place => {
    return (
      <MapMarker
        place={place}
        key={place.id}
        anchor={[place.Latitude, place.Longitude]}
        onClick={setActivePlace}
      />
    );
  });
};

const mapSettings = {
  center: [43.2557, -79.8711],
  zoom: 12,
};

function MapPage() {
  const [activePlace, setActivePlace] = useState(null);

  const { allPlace } = useStaticQuery(graphql`
    query {
      allPlace {
        nodes {
          ...placeFragment
        }
      }
    }
  `);

  const renderedMarkers = useMemo(() => {
    return renderMarkers(allPlace.nodes, setActivePlace);
  }, [allPlace.nodes]);

  return (
    <div>
      <MapContainerWrapper>
        <Map {...mapSettings} onClick={() => setActivePlace(null)}>
          {renderedMarkers}

          {activePlace && (
            <InfoWindow
              anchor={[activePlace.Latitude, activePlace.Longitude]}
              place={activePlace}
            />
          )}
        </Map>
      </MapContainerWrapper>
    </div>
  );
}

export default MapPage;
